import { useDashboardNavStore } from "~/store/dashboardNav.js";
import { useLeftPanelStore } from "~/store/useLeftPanelStore.js";

export default defineNuxtRouteMiddleware((to, from) => {
  const useDashboardNav = useDashboardNavStore();
  const { updateHeader } = useDashboardNav;

  const useLeftPanel = useLeftPanelStore();
  const { updateSubMenuActiveTab, updateActiveTab } = useLeftPanel;

  // console.log("==> to: ", to);

  const pathMap = {
    "/dashboard/overview": {
      header: "Analytics Overview",
      activeTab: "overview",
    },
    "/dashboard/collection": {
      header: "My Collections",
      activeTab: "collection",
    },
    "/dashboard/automation": {
      header: "Automations",
      activeTab: "automation",
    },
    "/dashboard/globe": {
      header: "Realtime Clicks Globe",
      activeTab: "globe",
    },
    "/dashboard/calendar": {
      header: "My Calendar",
      activeTab: "calendar",
    },
    "/dashboard/activities": {
      header: "Recent Activity",
      activeTab: "activities",
    },
    "/dashboard/settings": {
      header: "Settings",
      activeTab: "settings",
    },
  };

  const pathSegments = to.fullPath.split("/");
  const basePath = `/${pathSegments[1]}/${pathSegments[2]}`;

  const { header, activeTab } = pathMap[basePath] || "";

  const subMenuMap = {
    "dashboard-overview-clicks": "overview-clicks",
    "dashboard-overview-locations": "overview-locations",
    "dashboard-overview-traffic-sources": "overview-traffic-sources",
  };

  const activeSubMenu = subMenuMap[to.name] || undefined;

  updateHeader(header);
  updateActiveTab(activeTab);
  updateSubMenuActiveTab(activeSubMenu);
});
